import { Autoplay, EffectFade, Swiper } from 'swiper';

export function initSlider(selector = '.swiper') {
  const el = document.querySelector(selector);

  return new Swiper(el, {
    modules: [EffectFade, Autoplay],
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    loop: true,
    autoplay: {
      delay: 3000,
      stopOnLastSlide: false,
      disableOnInteraction: true,
      pauseOnMouseEnter: false,
    },
  });
}
