import jsonp from 'jsonp';
import isemail from 'isemail';

const LIST_ID = '33511000a9';
const USER_ID = 'e907ea5d73ebbf6ba2399e02a';
const URL = 'https://ollieandco.us14.list-manage.com/subscribe/post-json';

let msgEl = null;
let formEl = null;
let fieldsEl = null;
let inputEl = null;

const validate = ({ email }) => {
  return isemail.validate(email);
};

const modifiers = {
  loading: 'is-loading',
  error: 'is-error',
  success: 'is-success',
  hidden: 'is-hidden',
};

const hideError = () => {
  if (msgEl.classList.contains(modifiers.hidden)) {
    return;
  }
  msgEl.classList.remove(modifiers.success);
  msgEl.classList.remove(modifiers.error);
  msgEl.classList.add(modifiers.hidden);
  msgEl.innerHTML = '';
};

const displayError = (msg) => {
  msgEl.classList.remove(modifiers.success);
  msgEl.classList.add(modifiers.error);
  msgEl.classList.remove(modifiers.hidden);
  msgEl.innerHTML = msg;
};

const displaySuccess = (msg) => {
  fieldsEl.classList.add(modifiers.hidden)
  msgEl.classList.remove(modifiers.error);
  msgEl.classList.add(modifiers.success);
  msgEl.classList.remove(modifiers.hidden);
  msgEl.innerHTML = msg;
};

const subscribe = () => {
  formEl = document.querySelector('[data-subscribe]');
  if (!formEl) {
    return;
  }

  msgEl = formEl.querySelector('[data-subscribe-message]');
  fieldsEl = formEl.querySelector('[data-subscribe-fields]');
  inputEl = formEl.querySelector('[data-subscribe-input]');
  if (!msgEl || !fieldsEl || !inputEl) {
    return;
  }

  inputEl.addEventListener('keyup', () => {
    hideError();
  });

  formEl.addEventListener('submit', (ev) => {
    ev.preventDefault();

    if (formEl.classList.contains(modifiers.loading)) {
      return;
    }

    const email = inputEl.value.trim();

    if (!validate({ email })) {
      displayError('Please enter a valid email address');
      return;
    }

    const data = stringify({ email });
    const url = `${URL}?${data}`;

    formEl.classList.add(modifiers.loading);
    jsonp(url, { param: 'c' }, (error, data) => {
      formEl.classList.remove(modifiers.loading);
      if (error) {
        displayError('Something went wrong. Please refresh the page and try again.');
        return;
      }

      if (data.result === 'error') {
        displayError(data.msg.replace('0 - ', ''));
        return;
      }
      displaySuccess('Thank you for subscribing!');
    });
  });
};

const stringify = ({ email }) => {
  return `u=${USER_ID}&id=${LIST_ID}&EMAIL=${encodeURIComponent(email)}`;
};

export default subscribe;
