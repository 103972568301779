import subscribe from "./scripts/subscribe";
import { initSlider } from './scripts/bg';

subscribe();
const slider = initSlider();

const els = document.querySelectorAll('.header, .main');
els.forEach((el) => {
  el.addEventListener('click', () => {
    slider.slideNext();
  });
});
